import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadService } from '../../../common/load.service';

import { LoadRouteComponent } from '../load-route/load-route.component';
import { MatDialog } from '@angular/material';
import { finalize, mergeMap, catchError, debounceTime } from 'rxjs/operators';
import { RouteService } from '../../../common/route.service';
import { Subject, of } from 'rxjs';
import { MySQLDecimalValidator } from '../../../common/mysql-decimal-validator';
import { MySQLIntValidator } from '../../../common/mysql-int-validator';

@Component({
    selector: 'app-load',
    templateUrl: './load.component.html',
    styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {

    @Input() isBolComplete = false;

    @Input()
    public load = null;

    @Input()
    public bolRoute = null;

    @Input()
    public archived = false;

    @Input()
    public billingTypeName = null;

    @Input()
    public billingTypeOptionalName = null;

    @Input()
    public sync: Subject<any> = null;

    @Output()
    public destroy = new EventEmitter();

    @Output()
    public change = new EventEmitter();

    @Output()
    public saveComplete = new EventEmitter();

    public loading = false;
    public saveLoading = false;
    public loadRoute = null;
    public loadImages = [];
    public hasChanged = false;
    public online;
    public hasChangedImage = false;
    form: FormGroup;

    public toPickup;
    public toDropoff;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private dialog: MatDialog,
                private route: ActivatedRoute,
                private cd: ChangeDetectorRef,
                private loadService: LoadService,
                private routeService: RouteService) {
    }

    ngOnInit() {
        this.online = navigator.onLine;
        if (!('id' in this.load)) {
            this.createLoad();
        } else if ('route' in this.load && this.load.route !== null) {
            this.loadRoute = this.load.route;
            delete this.load.route;
        } else {
            this.createRoute();
        }

        if ('images' in this.load) {
            this.loadImages = this.loadImages.concat(this.load.images);
            delete this.load.images;
        }

        this.form = this.formBuilder.group({
            load: this.formBuilder.group({
                billable_quantity: [this.load.billable_quantity || null, MySQLDecimalValidator(13, 3)],
                billable_quantity_optional: [this.load.billable_quantity_optional || null, MySQLDecimalValidator(13, 3)],
                estimated_loads_remaining: [this.load.estimated_loads_remaining || null, MySQLIntValidator()],
                load_images: this.loadImages,
                status: this.load.status || null,
            }),

        });

        this.form.valueChanges.pipe(debounceTime(2000))
            .subscribe((changes) => {
                this.hasChanged = true;
                this.change.emit(changes);
                this.saveLoad();
            });

        this.sync.subscribe((online) => {
            this.online = online;
            if ( online === true ) {
                if (!('id' in this.load)) {
                    this.createLoad();
                } else {
                    this.saveLoad();
                }
           }
        });

        this.setupLocationLinks();
    }

    private mapsUrl(origin, destination) {
        return `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;
    }

    private setupLocationLinks() {
        if (!this.loadRoute) {
            return;
        }
        const route = this.loadRoute;
        this.toDropoff = this.toPickup = this.mapsUrl(route.pickup_location, route.dropoff_location);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const currentPosition = position.coords.latitude + ',' + position.coords.longitude;
                this.toPickup = this.mapsUrl(currentPosition, route.pickup_location);
                this.toDropoff = this.mapsUrl(currentPosition, route.dropoff_location);
            });
        }
    }

    private createLoad() {
        this.loading = true;
        const loadOptions = {
            ... this.load,
            status: 'created',
            billable_quantity: 0,
            estimated_loads_remaining: 0,
        };
        this.loadService.create(loadOptions).pipe(
            catchError( err => {
                this.change.emit();
                this.hasChanged = true;
                return of(Object.assign(this.load, loadOptions));
            })
        ).subscribe(load => {
            this.load = load;
            this.createRoute();
        }, err => {
            this.loading = false;
        });
    }

    private createRoute() {
        const routeOptions = Object.assign({}, this.bolRoute);
        delete routeOptions.id;
        delete routeOptions.created_at;
        this.loading = true;
        this.routeService.createLoadRoute(this.load.id, routeOptions).pipe(
            catchError(err => {
                this.hasChanged = true;
                this.change.emit();
                return of(routeOptions);
            }),
            finalize(() => this.loading = false),
        ).subscribe((res) => {
            this.loadRoute = res;
            this.saveLoad();
        });
    }

    changeRoute() {
        const route = this.loadRoute;
        let routeOptions = null;
        this.loading = true;
        const dialogRef = this.dialog.open(LoadRouteComponent, {
            width: '1200px',
            data: { route }
        });
        dialogRef.afterClosed().pipe(
            mergeMap((res) => {
                        if (res && res.success && res.success === true && res.route) {
                            routeOptions = Object.assign({}, route, res.route);
                            return this.routeService.update(routeOptions);
                        } else {
                            return of(null);
                        }
                    }),
            finalize(() => this.loading = false),
            catchError(err => {
                this.hasChanged = true;
                this.change.emit();
                return of(routeOptions);
            }),
        ).subscribe(updatedRoute => {
            // handling if the dialog was just closed
            if (!updatedRoute) {
                return;
            }
            this.loadRoute = updatedRoute;
            this.setupLocationLinks();
        });
    }

    imageChange(event) {
        if (event === 'done') {
            this.hasChangedImage = false;
        } else {
            this.hasChangedImage = true;
        }
    }

    saveLoad() {
        if (this.hasChanged && this.form.valid) {
            if (this.online) {
                this.saveLoading = true;
                const loadId = this.load.id;
                const payload = this.form.get('load').value;
                payload.bol_id = this.load.bol_id;
                this.loadService.update(payload, loadId).subscribe( () => {
                    this.hasChanged = false;
                    this.saveLoading = false;
                });
            }
        } else {
            this.saveComplete.emit(true);
        }
    }

    onDestroy() {
    }
}



