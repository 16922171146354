import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Observable, ReplaySubject} from "rxjs";
import {map, shareReplay} from "rxjs/operators";

export interface Trailer{
  name: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class TrailerService {

  private trailerSubject = new ReplaySubject(1);

  private cachedGetAll$ = null;


  get trailers() {
    return this.trailerSubject;
  }

  constructor(protected apiService: ApiService) {
    this.getAll();
  }

  public find(bol, sortOrder = 'asc', pageNumber = 1, limit = 25) {

    return this.apiService.get('bol-trailers', {
      params: {
        bol: bol.id,
        page: pageNumber.toString(),
        limit: limit.toString()
      }
    });
  }

  public getAll() {
    if(this.cachedGetAll$ === null) {
      this.cachedGetAll$ = this.apiService.get('trailers').pipe(shareReplay(1));
    }

    return this.cachedGetAll$;
  }

}
