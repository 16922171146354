import {Component, OnInit} from '@angular/core';
import {BolService} from "../../common/bol.service";
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-bill-of-lading-list',
    templateUrl: './bill-of-lading-list.component.html',
    styleUrls: ['./bill-of-lading-list.component.scss']
})
export class BillOfLadingListComponent implements OnInit {

    public bols = [];
    public loading = false;
    public bolsLoaded = false;
    public notifications = [
        {}
    ];
    public filter = null;
    public statuses = ['All', 'OTR', 'Regular']


    constructor(private bolService: BolService) {
    }

    ngOnInit() {
        this.loading = true;
        this.getBols()
    }

    getBols() {
        this.bolService.getBols(this.filter).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.bols = response.data;
            this.bolsLoaded = true;
        });
    }

    onDecline(bol) {
        const index = this.bols.indexOf(bol);
        this.bols.splice(index, 1);
    }

    handleStatusChange(e) {
        let status = e.value
        if (status === 'All') {
            this.filter = null;
        } else {
            this.filter = {is_otr: status === 'OTR' ? true : false}
        }
        this.getBols()
    }


}
