import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {BolService} from '../../../common/bol.service';
import {VehicleModalComponent} from '../../vehicle-modal/vehicle-modal.component';
import {MatDialog} from '@angular/material';
import {LocationsModalComponent} from '../../locations-modal/locations-modal.component';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'app-list-bill-of-lading',
    templateUrl: './bill-of-lading.component.html',
    styleUrls: ['./bill-of-lading.component.scss']
})
export class ListBillOfLadingComponent implements OnInit {

    @Input() bol;
    @Output() decline = new EventEmitter();

    public origin;
    public destination;
    public startTime;

    constructor(
        private bolService: BolService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        if (this.bol.job.started_at) {
            this.startTime = moment.utc(this.bol.job.started_at).local().format('l, h:mm a');
        }
    }

    accept(bol) {
        bol.loading = true;
        this.bolService.accept(bol).pipe(
            finalize(() => bol.loading = false)
        ).subscribe((response: any) => {
            this.bol.status = response.data.status;
            this.bol.accepted_at_formatted = response.data.accepted_at_formatted;
        });
    }

    onDecline(bol) {
        bol.loading = true;

        this.bolService.decline(bol).pipe(
            finalize(() => bol.loading = false)
        ).subscribe((response: any) => {
            this.bol = response.data;

            this.decline.emit(true);
        });
    }

    openVehicles() {
        const dialogRef = this.dialog.open(VehicleModalComponent, {
            width: '600px',
            data: {
                truck: this.bol.truck,
                trailer: this.bol.trailer,
                bol: this.bol
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.status === 'success') {
                this.bol[result.type] = result[result.type];
                this.updateVehicles(this.bol);
            }
        });

    }

    updateVehicles(bol) {
        this.bolService.updateVehicles(bol).subscribe((response: any) => {
            this.bol.truck = response.data.truck;
            this.bol.trailer = response.data.trailer;
        });
    }

    openLocations(route) {
        const dialogRef = this.dialog.open(LocationsModalComponent, {
            width: '600px',
            data: {
                route: route
            }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }
}
