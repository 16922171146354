import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BolService } from '../../../common/bol.service';
import { JobSafetyAnalysisService } from '../../../common/job-safety-analysis.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { MatDialog } from '@angular/material';
import { VehicleModalComponent } from '../../vehicle-modal/vehicle-modal.component';
import { LocationsModalComponent } from '../../locations-modal/locations-modal.component';
import { JobSafetyAnalysisComponent } from '../../job-safety-analysis/job-safety-analysis.component';

import * as moment from 'moment';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';

import { finalize, first } from 'rxjs/operators';
import { LoadService } from '../../../common/load.service';

@Component({
    selector: 'app-bill-of-lading',
    templateUrl: './bill-of-lading.component.html',
    styleUrls: ['./bill-of-lading.component.scss']
})
export class BillOfLadingComponent implements OnInit, OnDestroy {

    @Input() bolId;
    @Input() archived = false;

    @Output() reject = new EventEmitter();

    public bol;

    origin: any;
    destination: any;

    public loading: boolean;
    public changed = false;

    public loadsForm: FormGroup;
    public ableToStart = false;
    public timeRemaining = null;
    public startsAt = null;
    public startApproved = false;
    public disableClicked = false;

    public toPickup: string = null;
    public toDropoff: string = null;

    public subscriptions = [];

    public bolIsLoaded = new BehaviorSubject(false);
    public sync = new Subject<any>();
    public connectionStatus;

    public jsa = null;

    public loads = [];

    constructor(
        private bolService: BolService,
        private ngZone: NgZone,
        private router: Router,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private jsaService: JobSafetyAnalysisService,
        private loadService: LoadService,
    ) { }

    ngOnInit() {
        // set initial connection status
        this.connectionStatus = navigator.onLine;

        this.getBol(this.bolId);

        this.loadsForm = this.formBuilder.group({
            loads: this.formBuilder.array([])
        });

        let action = this.route.snapshot.queryParamMap.has('action') ?
            this.route.snapshot.queryParamMap.get('action') :
            null;

        if (action !== null) {
            this.subscriptions.push(this.bolIsLoaded.subscribe(loaded => {
                if (loaded) {
                    this.processAction(action);
                }
            }));
        }

        // sending children the signal that states the connection's state.
        const onlineEvent = fromEvent(window, 'online');
        const offlineEvent = fromEvent(window, 'offline');
        onlineEvent.subscribe(() => {
            this.connectionStatus = true;
            this.sync.next(true);
        });
        offlineEvent.subscribe( () => {
            this.connectionStatus = false;
            this.sync.next(false);
        });

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    processAction(action) {

        if (action === 'accept') {
            this.accept(this.bol);
        } else if (action === 'view_comments') {
            this.openComments();
        }

        this.router.navigate([], {
            queryParams: {
                action: null,
            },
            queryParamsHandling: 'merge'
        });
    }


    getBol(id) {
        this.loading = true;
        this.subscriptions.push(this.bolService.get(id).subscribe((response: any) => {
            this.bol = response.data;

            this.getJsa(this.bol);

            // format created at date
            const createdAtFormatted = moment(this.bol.created_at).format('dddd, MMMM Do, YYYY');
            this.bol.created_at_formatted = createdAtFormatted === 'Invalid date' ? null : createdAtFormatted;

            this.bolIsLoaded.next(true);

            const route = this.bol.route;
            this.toPickup = `https://www.google.com/maps/dir/?api=1&origin=${route.pickup_location}&destination=${route.dropoff_location}&travelmode=driving`;
            this.toDropoff = `https://www.google.com/maps/dir/?api=1&origin=${route.pickup_location}&destination=${route.dropoff_location}&travelmode=driving`;

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const currentPosition = position.coords.latitude + ',' + position.coords.longitude;
                    this.toPickup = `https://www.google.com/maps/dir/?api=1&origin=${currentPosition}&destination=${route.pickup_location}&travelmode=driving`;
                    this.toDropoff = `https://www.google.com/maps/dir/?api=1&origin=${currentPosition}&destination=${route.dropoff_location}&travelmode=driving`;
                });
            }

            this.checkStartTime(response.data);
            setTimeout( () => this.checkStartTime(response.data), 1000 * 60);

            this.origin = this.bol.route.pickup_location;
            this.destination = this.bol.route.dropoff_location;

            this.loads = this.bol.loads;
            // this.initializeLoads(this.bol.loads);

            this.loading = false;
        }));
    }

    getJsa(bol) {
        this.jsaService.find([bol.jsa_id]).subscribe(jsa => {
            if (!jsa || jsa.length === 0) {
                jsa = null;
            } else {
                this.jsa = jsa[0];
            }
        });
    }


    disabledClicked() {
        this.disableClicked = true;
    }

    checkStartTime(bol) {
        // If it has no start time...
        if (!bol.job.started_at) {
            this.startApproved = true;
            return;
        }

        this.startsAt = moment.utc(bol.job.started_at).local();
        const now = moment();
        const difference = moment.duration(this.startsAt.diff(now));
        if (difference.asHours() <= 6) {
            this.startApproved = true;
        }
        if (difference.asHours() <= 24) {
            this.timeRemaining = `${difference.hours()} hour(s) and ${difference.minutes()} minute(s)`;
        } else {
            this.timeRemaining = difference.humanize();
        }
    }

    accept(bol) {
        this.loading = true;
        this.subscriptions.push(this.bolService.accept(bol).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.bol.status = response.data.status;
        }));
    }

    decline(bol) {
        this.loading = true;
        this.subscriptions.push(this.bolService.decline(bol).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.reject.emit(bol);
        }));
    }

    start(bol) {
        this.loading = true;
        this.subscriptions.push(this.bolService.start(bol).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.bol.status = response.data.status;

            // create first load
            this.addLoad();
        }));
    }

    updateVehicles(bol) {
        this.loading = true;
        this.subscriptions.push(this.bolService.updateVehicles(bol).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.bol.truck = response.data.truck;
            this.bol.trailer = response.data.trailer;
        }));
    }

    finish(bol) {
        this.loading = true;
        this.subscriptions.push(this.bolService.finish(bol).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }));
    }

    onLoadDestroy(load) {

        const index = this.loadsForm.get('loads').value.findIndex(formLoad => formLoad.id === load.id);
        const loads = this.loadsForm.get('loads') as FormArray;
        loads.removeAt(index);
    }

    addLoad() {
        this.loads.unshift({
            bol_id: this.bol.id,
            number: this.loads.length + 1,
        });
    }

    private initializeLoads(loads) {
        for (let load of loads) {
            let loads = this.loadsForm.get('loads') as FormArray;
            loads.push(this.formBuilder.group({
                id: load.id,
                status: load.status,
                number: load.number,
                estimated_loads_remaining: load.estimated_loads_remaining,
                billable_quantity: load.billable_quantity,
                is_finished: load.status === 'finished',
                route: load.route,
                picture: load.picture || null,
                encoded_images: new FormArray([])
            }));
        }
    }

    onLoadChange(changes) {
        if (this.bol.status !== 'finished') {
            this.changed = true;
        }
    }

    openComments() {
        const dialogRef = this.dialog.open(CommentModalComponent, {
            width: '600px',
            data: {
                bol: this.bol,
                archived: this.archived
            },
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    openVehicles() {
        const dialogRef = this.dialog.open(VehicleModalComponent, {
            width: '900px',
            data: {
                truck: this.bol.truck,
                trailer: this.bol.trailer,
                bol: this.bol,
                archived: this.archived
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.status === 'success') {
                this.bol[result.type] = result[result.type];
                this.updateVehicles(this.bol);
            }
        });

    }

    openLocations() {
        const dialogRef = this.dialog.open(LocationsModalComponent, {
            width: '600px',
            data: {
                route: this.bol.route
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    createJsa() {
        this.router.navigate(['jsa', { id: this.bolId, id2: 'new' }])
    }

    editJsa() {
        this.router.navigate(['jsa', { id: this.bolId, id2: this.jsa.id }])
    }

    formatTime(time) {
        if (time) {
            return moment.utc(time).local().format('l, h:mm a');
        }
    }

}
