import {Component, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger, MatSnackBar} from "@angular/material";
import {NotificationService} from "../../common/notification.service";
import {ApiService} from "../../common/api.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-notification-dropdown',
    templateUrl: './notification-dropdown.component.html',
    styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit {

    public notifications = [];
    public notificationQueue = [];

    public unread = 0;
    @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

    constructor(private notificationService: NotificationService,
                private apiService: ApiService,
                private router: Router,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.notificationService.unreadNotifications$.subscribe((response: any) => {
            this.notificationQueue = [...response];
            this.unread = this.notificationQueue.length;

            this.notifications = this.fillNotifications([]);
        });

    }

    ngAfterViewInit(): void {
        this.ddTrigger.menuOpened.subscribe(() => {});
    }

    value: string;
    selectedValue: string;

    cancelClick(ev: MouseEvent) {
        ev.stopPropagation();
    }

    onCancel() {
        this.value = undefined;
        this.ddTrigger.closeMenu();
    }

    onNotificationClick(notification) {
        if(notification.data.url) {
            this.router.navigateByUrl(notification.data.url);
            this.toggleRead(null, notification);
            return;
        }
    }

    toggleRead(event = null, notification) {
        if(event !== null) {
            event.stopPropagation();
        }

        notification.data.read = !notification.data.read;

        this.unread -= 1;

        this.notificationService.markAsRead(notification, 'notification-dropdown').subscribe(response => {
        });
    }

    public markAllAsRead() {
        this.notificationService.markAllAsRead('notification-dropdown').subscribe(response => {
        });
    }

    private fillNotifications(notifications) {
        if(notifications.length < 8) {
            if(this.notificationQueue.length > 0) {
                let filler = this.notificationQueue.shift();
                notifications.push(filler);
                return this.fillNotifications(notifications);
            }

            return notifications;
        }

        return notifications;
    }

}
