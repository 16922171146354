import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JobSafetyAnalysisService } from '../../common/job-safety-analysis.service';
import { BolService } from '../../common/bol.service';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-job-safety-analysis',
  templateUrl: './job-safety-analysis.component.html',
  styleUrls: ['./job-safety-analysis.component.scss']
})
export class JobSafetyAnalysisComponent implements OnInit {

  public jsaGroup: FormGroup;

  constructor(private fb: FormBuilder,
              private jsaService: JobSafetyAnalysisService,
              private snackbar: MatSnackBar,
              private router: Router,
              private route: ActivatedRoute,
              private bolService: BolService) {
  }

  protected _onDestroy = new Subject<void>();

  public mode = 'new';
  public loading;
  public bolId;
  public bol;
  public jsaId = null;

  public taskContentError;
  public missingTask = false;
  public customerSigMissing = false;
  public employeeSigMissing = false;
  public pageLoading = false;

  public employeeSigImage;
  public employeeEditable = true;
  public customerSigImage;
  public customerEditable = true;
  public tasks = new Array();
  public otherPpe = new Array();
  public otherKeyItems = new Array();
  public errorMessage;
  public requiredPpe = new Array('Hard Hat', 'Steel Toed Boots', 'Safety Glasses', 'FR Clothing', 'H2S Monitor');
  public keyItems = new Array('Area Surroudings', 'Overhead', 'Backing Up', 'Pinch Points', 'Slip Hazards', 'Ground Conditions', 'Spotter', 'Obstacles', 'Lighting', 'Loading/Off-loading Location', 'Muster Point/ Area Exit Area');

  @ViewChild('taskTitle') taskCardTitle: ElementRef;
  @ViewChild('hazard') taskCardHazard: ElementRef;
  @ViewChild('controls') taskCardControl: ElementRef;
  @ViewChild('ppe') otherPpeInput: ElementRef;
  @ViewChild('keyItem') otherKeyItemInput: ElementRef;

  ngOnInit() {
    this.initializeForm();
    this.init();
  }

  public init() {
    const bolParam = this.route.snapshot.params.id;
    const jsaParam = this.route.snapshot.params.id2;

    this.bolId = +bolParam;
    this.findBol(this.bolId);

    if (jsaParam && jsaParam !== 'new') {
        this.mode = 'edit';
        this.findJsa(jsaParam);
        this.jsaId = jsaParam;
    } else {
        this.loading = false;
    }
  }

  public findBol(id) {
    this.bolService.get(id).pipe(
      finalize(() => this.loading = false)
    ).subscribe(response => {
        this.bol = response;
    });
  }

  public findJsa(id) {
    this.jsaService.find([id]).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
        this.jsaGroup.patchValue({
        weather_conditions: response[0].weather_conditions,
        equipment_type: response[0].equipment_type,
        key_items: JSON.parse(response[0].key_items),
        required_ppe: JSON.parse(response[0].required_ppe),
        safety_concerns: response[0].safety_concerns,
        customer_name_info: response[0].customer_name_info
        });
        this.tasks = JSON.parse(response[0].tasks);
        this.otherKeyItems = JSON.parse(response[0].other_key_items);
        this.otherPpe = JSON.parse(response[0].other_ppe);
        this.jsaService.findSignature('customer', id).subscribe(data => {
          this.customerSigImage = data;
          this.customerEditable = false;
        });
        this.jsaService.findSignature('employee', id).subscribe(data => {
          this.employeeSigImage = data;
          this.employeeEditable = false;
        });
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeForm() {
    this.jsaGroup = this.fb.group({
      weather_conditions: ['', Validators.required],
      equipment_type: ['', Validators.required],
      key_items: ['', Validators.required],
      required_ppe: ['', Validators.required],
      safety_concerns: ['', Validators.required],
      customer_name_info: ['', Validators.required]
    });
  }

  saveEmployeeSigImage(image) {
    if (this.signatureSize(image) < 2000) {
      return;
    }
    this.employeeSigImage = image;
    this.employeeSigMissing = false;
    this.employeeEditable = false;
  }

  editEmployeeSig() {
    this.employeeEditable = true;
    this.employeeSigImage = undefined;
  }

  saveCustomerSigImage(image) {
    if (this.signatureSize(image) < 2000) {
      return;
    }
    this.customerSigImage = image;
    this.customerSigMissing = false;
    this.customerEditable = false;
  }

  editCustomerSig() {
    this.customerEditable = true;
    this.customerSigImage = undefined;
  }

  signatureSize(base64) {
    return 4 * Math.ceil((base64.length / 3));
  }

  addTask(hazard, controls, taskTitle) {
    if (hazard.length > 0 && controls.length > 0 && taskTitle.length > 0) {
      let task = new Object({
        taskTitle,
        hazard,
        controls
      });

      this.missingTask = false;
      this.taskContentError = false;
      this.tasks.push(task);
      this.taskCardTitle.nativeElement.value = '';
      this.taskCardHazard.nativeElement.value = '';
      this.taskCardControl.nativeElement.value = '';
    } else {
      this.taskContentError = true;
    }
  }

  removeTask(taskIndex) {
    this.tasks.splice(taskIndex, 1);
  }

  addOtherPpe(ppe) {
    if (ppe.length > 0) {
      this.otherPpe.push(ppe);
      this.otherPpeInput.nativeElement.value = '';
    }
  }

  removePpe(ppeIndex) {
    this.otherPpe.splice(ppeIndex, 1);
  }

  addOtherKeyItem(keyItem) {
    if (keyItem.length > 0) {
      this.otherKeyItems.push(keyItem);
      this.otherKeyItemInput.nativeElement.value = '';
    }
  }

  removeKeyItem(keyItemIndex) {
    this.otherKeyItems.splice(keyItemIndex, 1);
  }

  formIsValid() {
    let valid = true;
    if (this.employeeSigImage === undefined || this.customerSigImage === undefined) {
      if (this.employeeSigImage === undefined) {
        this.employeeSigMissing = true;
      }
      if (this.customerSigImage === undefined) {
        this.customerSigMissing = true;
      }
      this.errorMessage = 'Missing signature(s)';
      valid = false;
    }
    if (this.tasks.length === 0) {
      this.missingTask = true;
      this.errorMessage = 'Missing tasks';
      valid = false;
    }
    if (!this.jsaGroup.valid) {
      this.errorMessage = 'Missing Item(s)';
      valid = false;
    }

    return valid;
  }

  collectForm() {
    return {
      weather_conditions: this.jsaGroup.controls.weather_conditions.value,
      equipment_type: this.jsaGroup.controls.equipment_type.value,
      key_items: this.jsaGroup.controls.key_items.value,
      required_ppe: this.jsaGroup.controls.required_ppe.value,
      safety_concerns: this.jsaGroup.controls.safety_concerns.value,
      customer_name_info: this.jsaGroup.controls.customer_name_info.value,
      other_ppe: this.otherPpe,
      other_key_items: this.otherKeyItems,
      employee_signature: this.employeeSigImage,
      customer_signature: this.customerSigImage,
      tasks: this.tasks
    };
  }

  submitJsa() {
    if (!this.formIsValid()) {
      return;
    }
    this.pageLoading = true;
    this.jsaService.saveOrUpdate(this.collectForm(), this.jsaId).pipe(
            finalize(() => this.pageLoading = false)
        ).subscribe(response => {
        const snackConfig = new MatSnackBarConfig();
        snackConfig.panelClass = ['custom-class'];
        snackConfig.duration = 3000;
        const popup = this.snackbar.open('JSA saved successfully', 'Close', snackConfig);
        popup.onAction().subscribe(() => popup.dismiss());

        // If they are creating a new jsa it will be linked to a bol
        if (this.jsaId == null) {
          let data = {
            jsa_id: response.id,
            bol: this.bol.data
          };
          this.bolService.updateJsaId(data);
        }

        this.router.navigate(['/bols' + '/' + this.bolId]);

      });
  }
}
