import { ApiService } from "./api.service";
import { ReplaySubject } from "rxjs";
import { shareReplay } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var TrailerService = /** @class */ (function () {
    function TrailerService(apiService) {
        this.apiService = apiService;
        this.trailerSubject = new ReplaySubject(1);
        this.cachedGetAll$ = null;
        this.getAll();
    }
    Object.defineProperty(TrailerService.prototype, "trailers", {
        get: function () {
            return this.trailerSubject;
        },
        enumerable: true,
        configurable: true
    });
    TrailerService.prototype.find = function (bol, sortOrder, pageNumber, limit) {
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (pageNumber === void 0) { pageNumber = 1; }
        if (limit === void 0) { limit = 25; }
        return this.apiService.get('bol-trailers', {
            params: {
                bol: bol.id,
                page: pageNumber.toString(),
                limit: limit.toString()
            }
        });
    };
    TrailerService.prototype.getAll = function () {
        if (this.cachedGetAll$ === null) {
            this.cachedGetAll$ = this.apiService.get('trailers').pipe(shareReplay(1));
        }
        return this.cachedGetAll$;
    };
    TrailerService.ngInjectableDef = i0.defineInjectable({ factory: function TrailerService_Factory() { return new TrailerService(i0.inject(i1.ApiService)); }, token: TrailerService, providedIn: "root" });
    return TrailerService;
}());
export { TrailerService };
