import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OfflineActionsService } from './offline-actions.service';
import { Observable, of } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { tap } from 'rxjs/internal/operators/tap';
import { Utility } from './utility';
import { SessionService } from './session.service';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class BolService {

    private uri = 'bols';

    constructor(private apiService: ApiService,
                private localStorage: LocalStorage,
                private sessionService: SessionService,
                private offlineActionService: OfflineActionsService) {
    }

    get bols() {
        const uri = `me/${this.uri}`;

        return this.apiService.get(uri);
    }

    getBols(filters = null) {
        const uri = `me/${this.uri}`;

        return this.apiService.get(uri, {params: filters});
    }

    public getArchivedBols(page) {
        const uri = `me/${this.uri}?status=archived&page=${page}`;

        return this.apiService.get(uri);
    }

    public syncLoads(id, bol) {
        const uri = `${this.uri}/${id}/loads`;

        if (!bol.loads) {
            return;
        }

        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'sync_bol_loads',
                data: bol,
                method: 'post',
                uri: uri,
                payload: bol,
                performed_at: new Date().toUTCString()
            });

            this.updateCached(bol, uri);

            return of({
                data: bol
            });
        }

        return this.apiService.post(uri, bol).pipe(tap((response: any) => {
            this.updateCached(response.data, uri, false);
        }));
    }

    public accept(bol) {
        const payload = {
            status: 'accepted'
        };

        const uri = `${this.uri}/${bol.id}`;

        const accept$ = this.apiService.put(`${this.uri}/${bol.id}`, payload).pipe(tap((response) => {
            bol.status = 'accepted';
            this.updateCached(bol, uri);
        }));

        return accept$;
    }

    public updateVehicles(bol): Observable<any> {
        const payload = {
            truck: bol.truck.id,
            trailer: bol.trailer.id
        };

        const uri = `${this.uri}/${bol.id}`;

        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'update_vehicles',
                data: bol,
                method: 'put',
                uri: uri,
                payload: payload,
                performed_at: new Date().toUTCString()
            });

            this.updateCached(bol, uri);

            return of({
                data: {
                    truck: bol.truck,
                    trailer: bol.trailer
                }
            });
        }

        return this.apiService.put(uri, payload);
    }

    public start(bol): Observable<any> {
        const payload = {
            status: 'started'
        };

        const uri = `${this.uri}/${bol.id}`;

        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'start_bol',
                data: bol,
                method: 'put',
                uri: uri,
                payload: payload,
                performed_at: new Date().toUTCString()
            });

            bol.status = 'started';
            bol.loads = [];
            this.updateCached(bol, uri);

            return of({
                data: {
                    status: 'started',
                    loads: []
                }
            });
        }

        return this.apiService.put(uri, payload);
    }

    public finish(bol) {
        const payload = {
            status: 'finished'
        };

        return this.apiService.put(`${this.uri}/${bol.id}`, payload);
    }

    public decline(bol) {
        const payload = {
            status: 'rejected'
        };

        return this.apiService.put(`${this.uri}/${bol.id}`, payload);
    }

    public getLinks() {
        return this.apiService.get(`me/bol-links`);
    }

    public get(id) {
        const uri = `${this.uri}/${id}`;

        if (!window.navigator.onLine) {
            // get cached
            return this.localStorage.getItem(uri);
        }

        return this.apiService.get(uri, {
            params: {
                include: 'route,truck,trailer,loads,loads.route,loads.images,job,job.well,job.billingType,job.billingTypeOptional,job.well.rig,job.material,job.dispatchers'
            }
        }).pipe(tap(response => {
            this.localStorage.setItem(uri, response).subscribe(response => {
            }, () => {
            });
        }));
    }

    updateJsaId(data) {
        var payload = data;
        const uri = `${this.uri}/${data.bol.id}`;
        this.apiService.put(uri, payload).subscribe();
    }

    public getComments(id) {
        const uri = `bols/${id}/comments`;

        if (!window.navigator.onLine) {
            // get cached
            return this.localStorage.getItem(uri);
        }

        return this.apiService.get(uri).pipe(tap(response => {
            this.localStorage.setItem(uri, response).subscribe(response => {
            }, () => {
            });
        }));
    }

    public saveComment(id, payload): Observable<any> {
        const uri = `bols/${id}/comments`;

        if (!window.navigator.onLine) {
            this.offlineActionService.addToQueue({
                type: 'save_comment',
                data: payload,
                method: 'post',
                uri: uri,
                payload: payload,
                performed_at: new Date().toUTCString()
            });

            const user = this.sessionService.user;
            payload.avatar_url = user.avatar_url;
            payload.user = user;
            payload.created_at_formatted = moment().format('M/D h:mm A');

            this.updateCachedComments(payload, uri);

            return of({
                data: payload
            });
        }

        return this.apiService.post(uri, payload);
    }

    public updateCached(bol, key, merge = true) {

        this.localStorage.getItem(key).subscribe(response => {

            let cached = response;

            if (cached === null) {
                cached = bol;
            } else {
                if (merge) {
                    cached.data = { ...cached.data, ...bol };
                } else {
                    cached.data = bol;
                }
            }

            this.localStorage.setItem(key, cached).subscribe(response => {

            }, () => {

            });

        }, () => {

        });
    }

    public updateCachedComments(comment, key) {
        this.localStorage.getItem(key).subscribe(response => {

            let cached = response;

            if (cached === null) {
                cached = [comment];
            } else {
                cached.data.push(comment);
            }

            this.localStorage.setItem(key, cached).subscribe(response => {

            }, () => {

            });

        }, () => {

        });
    }

    createFormData(object: Object, form?: FormData, namespace?: string): FormData {
        const formData = form || new FormData();
        for (let property in object) {
            if (!object.hasOwnProperty(property) || !object[property]) {
                continue;
            }
            const formKey = namespace ? `${namespace}[${property}]` : property;
            if (object[property] instanceof Date) {
                formData.append(formKey, object[property].toISOString());
            } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
                this.createFormData(object[property], formData, formKey);
            } else {
                formData.append(formKey, object[property]);
            }
        }
        return formData;
    }
}
