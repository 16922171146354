<div class="bol">
    <ngx-loading [show]="loading"></ngx-loading>
    <div fxLayout="row wrap">
        <div class="body-container" fxFlex.gt-sm="60" fxFlex.lt-md="100" fxLayout="row wrap">
            <div class="header-container">
                <div class="info" fxFlex="50">
                    <div class="info-icon-wrapper">
                        <div class="info-icon">
                            <i *ngIf="!bol?.job?.is_otr" class="material-icons">
                            waves
                            </i>
                            <i *ngIf="bol?.job?.is_otr" class="material-icons">
                                local_shipping
                            </i>
                        </div>
                    </div>

                    <div class="info-text">
                        <p class="job">
                            Job {{ bol?.job?.number }}
                        </p>
                        <h1>
                            Bill of Lading {{ bol?.number }}
                        </h1>
                        <p class="bol-date">
                            {{ bol?.created_at_formatted }}
                        </p>
                        <ng-container *ngIf="startsAt">
                            <p class="assigned-time"><strong>Assigned Time:</strong> {{ formatTime(startsAt) }}</p> 
                            <p class="time-remaining" *ngIf="bol?.status === 'accepted'">Time until assigned start time: {{timeRemaining}}</p>
                        </ng-container>
                    </div>

                </div>

                <div fxFlex="50">
                    <ng-container *ngIf="bol?.status !== 'created'">
                        <p class="status">
                            <ng-container *ngIf="bol?.status === 'accepted'">
                                <mat-icon style="vertical-align: bottom">check</mat-icon>
                                ACCEPTED
                            </ng-container>
                            <ng-container *ngIf="bol?.status === 'started'">
                                <mat-icon style="vertical-align: bottom">check</mat-icon>
                                STARTED
                            </ng-container>
                        </p>
                        <p *ngIf="jsa && bol?.status == 'started' && !bol?.job?.is_otr" class="status">
                            <mat-icon style="vertical-align: bottom">check</mat-icon>
                            JSA
                        </p>
                        <p *ngIf="jsa == null && bol?.status == 'started' && !bol?.job?.is_otr" class="missing">
                            <mat-icon style="vertical-align: bottom">clear</mat-icon>
                            JSA
                        </p>
                        <div class="status-actions-wrapper">
                            <ng-container *ngIf="bol?.status === 'started'">
                                <button [disabled]="jsa == null && !bol?.job?.is_otr" (click)="finish(bol)" mat-flat-button type="button" color="primary">Finish BOL</button>
                            </ng-container>
                            <ng-container *ngIf="bol?.status === 'accepted'">
                                <div (click)="disabledClicked()">
                                    <button [disabled]="!startApproved" (click)="start(bol)" mat-flat-button type="button" color="primary">Start BOL</button>
                                </div>
                            </ng-container>
                        </div>

                        <ng-container>
                            <div *ngIf="bol?.status === 'started' && !bol?.job?.is_otr" class="status-actions-wrapper">
                                <ng-container *ngIf="jsa == null">
                                    <button (click)="createJsa()" mat-flat-button type="button" color="primary">Create JSA</button>
                                </ng-container>
                                <ng-container *ngIf="jsa != null">
                                    <button (click)="editJsa()" mat-flat-button type="button" color="primary">Edit JSA</button>
                                </ng-container>
                            </div>
                        </ng-container> 
                  
                    </ng-container>



                    <ng-container *ngIf="bol?.status === 'created'">
                        <div style="float: right" class="status-actions-wrapper">
                            <button offline-disable (click)="accept(bol)" mat-flat-button type="button" color="primary">Accept BOL</button>
                            <button offline-disable (click)="decline(bol)" mat-flat-button type="button">Decline</button>
                        </div>

                    </ng-container>
                </div>
            </div>

            <div class="six-hour-error" *ngIf="!startApproved && disableClicked">
                <mat-icon class="time-warning-icon">error_outline</mat-icon>
                <p class="time-warning">Bol cannot be started more than 6 hours before the assigned time.</p>
            </div>

            <div class="bol-info" fxLayout="row wrap" fxLayoutAlign="center" fxFlex="100">
                <div *ngIf="bol?.job?.description" fxFlex="100" style="border-bottom: 0; border-left: 0; border-right: 0">
                    <p><b>Job Description:</b></p>
                    <p>{{ bol.job.description }}</p>
                </div>
                <div fxFlex="25" fxFlex.gt-md="25" fxFlex.lt-md="50">
                    <b>{{ bol?.job?.is_otr ? 'Warehouse': 'Well'}}: </b>
                    {{ bol?.job.well?.name || 'N/A' }}
                </div>
                <div fxFlex="25" fxFlex.gt-md="25" fxFlex.lt-md="50">
                    <b>{{bol?.job?.is_otr ? 'Dock': 'Rig'}}: </b>
                    {{ bol?.job.well?.rig?.name || 'N/A' }}
                </div>
                <div fxFlex="25" fxFlex.gt-md="25" fxFlex.lt-md="50">
                    <b>Hauling: </b>
                    {{ bol?.job.material?.name || 'N/A' }}
                </div>
                <div fxFlex="25" fxFlex.gt-md="25" fxFlex.lt-md="50">
                    <b>Billed By: </b>
                    {{ bol?.job.billing_type?.name || 'N/A' }}
                    <ng-container *ngIf="bol?.job.billing_type_optional">
                         and {{ bol?.job.billing_type_optional?.name }}
                    </ng-container>
                </div>
            </div>

            <div class="bol-comments" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" >

                <div class="external-action">
                    <button type="button" (click)="openVehicles()" mat-button>
                        <mat-icon>info</mat-icon>
                        Vehicles
                    </button>
                </div>

                <div class="external-action">
                    <button type="button" (click)="openComments()" mat-button>
                        <mat-icon>comments</mat-icon>
                        Comments
                    </button>
                </div>

                <div class="external-action">
                    <a target="_blank" rel="noopener noreferrer"  [href]="toPickup" mat-button>
                        <mat-icon>map</mat-icon>
                        To Pickup
                    </a>
                </div>

                <div class="external-action">
                    <a target="_blank" rel="noopener noreferrer"  [href]="toDropoff" mat-button>
                        <mat-icon>map</mat-icon>
                        To Dropoff
                    </a>
                </div>
            </div>

        </div>
        <div fxFlex.gt-sm="40" fxFlex.lt-md="95" class="map-container">
            <agm-map *ngIf="origin && destination" style="height: 400px">
                <agm-direction [origin]="origin" [destination]="destination">
                </agm-direction>
            </agm-map>
        </div>
    </div>

    <div class="load-container" *ngIf="bol?.status !== 'created'">
        <ng-container *ngIf="bol?.status === 'accepted'">
            Start BOL to add a load
        </ng-container>
        <ng-container *ngIf="bol?.status === 'started' || bol?.status === 'finished'">
            <button mat-flat-button color="primary" (click)="addLoad()" style="margin-bottom: 50px; margin-left: -24px" *ngIf=" ! this.archived && this.bol.status !== 'finished'">
                <mat-icon>add</mat-icon>
                Add Load
            </button>
            <ng-container *ngFor="let load of loads; let i = index;">
                <app-load [isBolComplete]="bol?.is_complete" [load]="load" [bolRoute]="bol?.route" [billingTypeName]="bol?.job.billing_type?.name" [billingTypeOptionalName]="bol?.job.billing_type_optional?.name" [archived]="archived" [sync]="sync" (destroy)="onLoadDestroy(load)" (change)="onLoadChange($event)"></app-load>
            </ng-container>
        </ng-container>
    </div>

    <footer class="offline-warning-footer" *ngIf="!connectionStatus">
        <p>You are currently offline... </p>
    </footer>
    
</div>

