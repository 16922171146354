<div class="bol">
    <ngx-loading [show]="bol?.loading"></ngx-loading>
    <div fxLayout="row-reverse wrap">
        <div fxFlex="100" fxFlex.gt-xs="40">
            <div class="map-container">
                <agm-map>
                    <agm-direction [origin]="bol?.route?.pickup_location" [destination]="bol?.route?.dropoff_location">
                    </agm-direction>
                </agm-map>
            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="60" fxLayout="row wrap">
            <div class="header-container">
                <div class="info" fxFlex="100">
                    <div class="info-icon">
                        <i *ngIf="!bol?.job?.is_otr" class="material-icons">
                            waves
                        </i>
                        <i *ngIf="bol?.job?.is_otr" class="material-icons">
                            local_shipping
                        </i>
                    </div>
                    <div class="info-text">
                        <p class="job">
                            Job {{ bol?.job.number }}
                        </p>
                        <div class="start-at-info">
                            <h1>
                                <a [routerLink]="['./', bol?.id]"> Bill of Lading {{ bol?.number }}</a>
                            </h1>
                            <p *ngIf="startTime">Assigned Time: {{startTime}}</p>   
                        </div>
                    </div>

                </div>
            </div>

            <div class="bol-info" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center">
                <div *ngIf="bol?.job?.description" fxFlex="100" class="job-description" style="border-bottom: 0; border-left: 0; border-right: 0">
                    <p><b>Job Description:</b></p>
                    <p>{{ bol.job.description }}</p>
                </div>
                <div fxFlex="50" fxFlex.gt-md="50" style="border-bottom: 0">
                    <b>{{bol?.job?.is_otr ? 'Warehouse' : 'Well'}}: </b>
                    {{ bol?.job.well?.name || 'N/A' }}
                </div>
                <div fxFlex="50" fxFlex.gt-md="50" style="border-bottom: 0; border-left: 0; border-right: 0">
                    <b>{{bol?.job?.is_otr ? 'Dock' : 'Rig'}}: </b>
                    {{ bol?.job.well?.rig?.name || 'N/A' }}
                </div>
                <div fxFlex="50" fxFlex.gt-md="50">
                    <b>Hauling: </b>
                    {{ bol?.job.material?.name || 'N/A' }}
                </div>
                <div fxFlex="50" fxFlex.gt-md="50" style="border-left: 0">
                    <b>Billed By: </b>
                    {{ bol?.job.billing_type?.name || 'N/A' }}
                    <ng-container *ngIf="bol?.job.billing_type_optional">
                         and {{ bol?.job.billing_type_optional.name }}
                    </ng-container>
                </div>
            </div>

            <div fxFlex="100" fxLayout="row wrap" class="vehicle-container">
                <div class="vehicle">
                    <button type="button" (click)="openVehicles()" mat-button>
                        <mat-icon>info</mat-icon>
                        Vehicles
                    </button>
                </div>

                <div class="vehicle">
                    <button type="button" mat-button (click)="openLocations(bol?.route)">
                        <mat-icon>map</mat-icon>
                        Locations
                    </button>
                </div>

                <div class="vehicle">
                    <button type="button" mat-button [routerLink]="['/view-bol', bol.id]">
                        <mat-icon>filter_none</mat-icon>
                        Show Bol
                    </button>
                </div>
            </div>

            <div fxFlex="100" class="dispatch-notes">
                <p><b>Dispatch Notes:</b></p>
                {{ bol?.job.notes }}
            </div>

            <ng-container *ngIf="bol?.status === 'created'">
                <div fxFlex="100" fxLayout="row" style="padding: 20px;">
                    <button offline-disable (click)="accept(bol)" mat-flat-button type="button" fxFlex="40" color="primary">Accept BOL</button>
                    <button offline-disable (click)="onDecline(bol)" mat-flat-button type="button" fxFlex="30" fxFlexOffset="10">Decline</button>
                </div>
            </ng-container>


            <ng-container *ngIf="bol?.status === 'accepted' || bol?.status === 'started'">
                <div fxFlex="100" fxLayout="row" style="padding: 20px;">
                    <div fxFlex="50">

                        <div class="accepted-text">
                            <p>
                                <mat-icon style="vertical-align: bottom">check</mat-icon>
                                <span>Accepted</span>
                                <br />
                                <small>at {{ bol?.accepted_at_formatted }}</small>
                            </p>
                        </div>

                    </div>
                    <div fxFlex="50">
                        <p style="text-align: right" class="started-text">
                            <ng-container *ngIf="bol?.status !== 'started'">
                                <i>BOL NOT STARTED</i>
                            </ng-container>
                            <ng-container *ngIf="bol?.status === 'started'">
                                Started at <br />
                                <small>{{ bol?.started_at_formatted }}</small>
                            </ng-container>
                        </p>

                    </div>
                </div>
            </ng-container>
        </div>

    </div>
</div>
