<div style="position: relative" class="background">  

  <h1>Job Safety Analysis - <small>BOL# {{bol?.data.id}}  Job# {{bol?.data.job_id}}</small></h1>

    <form [formGroup]="jsaGroup">
      <div class="bottom-pad" fxLayout="column" fxLayoutAlign="start start" >

        <div fxFlex="100" class="bottom-border">
          <div class="left-side">
            <mat-form-field class="left-column">
              <mat-label>Weather Conditions</mat-label>
              <textarea matInput formControlName="weather_conditions"></textarea>
            </mat-form-field>
            
            <mat-form-field class="left-column">
              <mat-label>Equipment Type</mat-label>
              <input matInput placeholder="Equipment Type" formControlName="equipment_type">
            </mat-form-field>
            
            <mat-form-field class="left-column">
              <mat-label>Required PPE</mat-label>
              <mat-select formControlName="required_ppe" multiple>
                <mat-option *ngFor="let ppe of requiredPpe" [value]="ppe">
                  {{ppe}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            
            <div class="left-column other-items">
              <mat-form-field class="other-ppe-field">
                <mat-label>Other PPE</mat-label>
                <input matInput #ppe placeholder="Other PPE" type="text">
              </mat-form-field>
              <button mat-raised-button (click)="addOtherPpe(ppe.value)">add</button>
              <mat-card class="card-row left-column" *ngIf="otherPpe.length > 0">
                <ng-container *ngFor="let ppe of otherPpe">
                  <div style="padding-right: 10px;">
                    <mat-card-content class="ppe">{{ppe}}</mat-card-content>
                    <button class="x-button" (click)="removePpe(otherPpe.indexOf(ppe))">x</button>
                  </div>
                </ng-container>
              </mat-card>
            </div>

            <mat-form-field class=left-column>
              <mat-label>Key Items</mat-label>
              <mat-select formControlName="key_items" multiple>
                <mat-option *ngFor="let item of keyItems" [value]="item">
                  {{item}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="left-column other-items">
              <mat-form-field class="other-key-item-field">
                <mat-label>Other Key Items</mat-label>
                <input matInput #keyItem placeholder="Other Key Item" type="text">
              </mat-form-field>
              <button mat-raised-button (click)="addOtherKeyItem(keyItem.value)">add</button>
              <mat-card class="card-row left-column" *ngIf="otherKeyItems.length > 0">
                <ng-container *ngFor="let keyItem of otherKeyItems">
                  <mat-card-content class="other-key-item">{{keyItem}}</mat-card-content>
                  <button class="x-button-key-item" (click)="removeKeyItem(otherKeyItems.indexOf(keyItem))">x</button>
                </ng-container>
              </mat-card>
            </div>

          </div>    


          <div class="right-side" fxFlex="40" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="start start" style="padding-left: 25px">


            <mat-form-field>
                <mat-label>Authorized Company Representative Safety Concerns</mat-label>
                <textarea matInput formControlName="safety_concerns"></textarea>
            </mat-form-field>

            <div class="bottom-pad">
              <mat-card class="tasks-card">
                <div class="task-flow">
                  <mat-card-title [className]="missingTask ? 'task-error-alert' : 'medium-text' ">Tasks</mat-card-title>
                  <mat-form-field class="task-input">
                  <input matInput #taskTitle placeholder="Task" type="text">
                  </mat-form-field>  
                  <mat-form-field class="task-input">
                  <input matInput #hazard placeholder="Hazard" type="text">        
                  </mat-form-field>
                  <mat-form-field class="task-input">
                  <input matInput #controls placeholder="Controls" type="text">     
                  </mat-form-field>  
                </div>  
                <div class="task-add-button">
                  <button  mat-raised-button (click)="addTask(hazard.value, controls.value, taskTitle.value)">add</button>
                </div>
                <p class="task-error-message" *ngIf="taskContentError">all fields are required</p>
              </mat-card> 
              <ng-container *ngFor="let task of tasks">
                <div class="body-card" *ngIf="tasks.indexOf(task) !< 3">
                  <mat-card>
                    <mat-card-title class="task-title">Task {{tasks.indexOf(task) + 1}}</mat-card-title>
                    <mat-card-subtitle>{{task.taskTitle}}</mat-card-subtitle>
                    <mat-card-content>Hazard: {{task.hazard}}</mat-card-content>
                    <mat-card-content>Controls: {{task.controls}}</mat-card-content>
                    <button class="right-justify" mat-raised-button (click)="removeTask(tasks.indexOf(task))">remove</button>
                  </mat-card> 
                </div>
              </ng-container>
              <p *ngIf="tasks.length > 3">... see below</p>         
            </div>


            <div class="signature-pad">
              <mat-label [className]="employeeSigMissing ? 'error' : '' ">Employee Signature</mat-label> 
              <ng-container *ngIf="employeeEditable">
                  <ng-signature-pad
                  width="375"
                  height="75"
                  doneButtonText="Done"
                  format="base64"
                  (done)="saveEmployeeSigImage($event)">
                  </ng-signature-pad>
              </ng-container>

              <ng-container *ngIf="employeeEditable == false">
                <img class="signature-image" [src]="employeeSigImage">
                <button *ngIf="mode == 'new'" class="signature-edit-button" (click)="editEmployeeSig()">Edit Signature</button>
              </ng-container>   
          
            </div>           

            <div class="signature-pad">
              <mat-label [className]="customerSigMissing ? 'error' : '' ">Customer Signature</mat-label>   
              <ng-container *ngIf="customerEditable">
                  <ng-signature-pad
                  width="375"
                  height="75"
                  doneButtonText="Done"
                  doneButtonClass="doneButton"
                  format="base64"
                  (done)="saveCustomerSigImage($event)">
                  </ng-signature-pad>   
              </ng-container>    

              <ng-container *ngIf="customerEditable == false">
                  <img class="signature-image" src={{customerSigImage}}>
                  <button *ngIf="mode == 'new'" class="signature-edit-button" (click)="editCustomerSig()">Edit Signature</button>
              </ng-container>  
            </div>
            <mat-form-field>
              <textarea formControlName="customer_name_info" matInput #sigInfo placeholder="Customer Name/Info" type="text"></textarea>
            </mat-form-field>  

          </div>    
        </div>  
        
          <ng-container *ngIf="errorMessage">
            <p class="error-message">{{errorMessage}}</p>
          </ng-container> 

          <div class="submit-button">
            <button class="medium-text" mat-raised-button (click)="submitJsa()" [disabled]="pageLoading">Save</button>
          </div>  
      </div>    
    </form>
    <div *ngIf="tasks.length > 3" class="footer-tasks">
        <ng-container *ngFor="let task of tasks">
          <div class="footer-card">
            <mat-card>
              <mat-card-title class="medium-text">Task {{tasks.indexOf(task) + 1}}</mat-card-title>
              <mat-card-subtitle>{{task.taskTitle}}</mat-card-subtitle>
              <mat-card-content>Hazard: {{task.hazard}}</mat-card-content>
              <mat-card-content>Controls: {{task.controls}}</mat-card-content>
              <button class="right-justify" mat-raised-button (click)="removeTask(tasks.indexOf(task))">remove</button>
            </mat-card> 
          </div>
        </ng-container>   
      </div>    
</div>
